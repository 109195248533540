<template>
    <v-row>
        <v-col cols="12" lg="9">
            <v-row>
                <v-col cols="12" md="4">
                    <base-card>
                        <v-list-item three-line>
                            <v-list-item-content>
                                <!-- <v-list-item-subtitle class="body-2">Total</v-list-item-subtitle> -->
                                <div class="body-2 mb-0 text--disabled">
                                    SOUCRIPTION ODD
                                </div>
                                <v-list-item-title
                                    class="headline mb-1 font-weight-bold"
                                    >0000</v-list-item-title
                                >
                            </v-list-item-content>

                            <v-avatar
                            color="primary"
                            size="56"
                            ></v-avatar>
                        </v-list-item>
                    </base-card>
                </v-col>
                    <v-col cols="12" md="4">
                    <base-card>
                        <v-list-item three-line>
                            <v-list-item-content>
                                <!-- <v-list-item-subtitle class="body-2">Total</v-list-item-subtitle> -->
                                <div class="body-2 mb-0 text--disabled">
                                   SOUSCRIPTION PDC
                                </div>
                                <v-list-item-title
                                    class="headline mb-1 font-weight-bold"
                                    >0000</v-list-item-title
                                >
                            </v-list-item-content>

                            <v-avatar
                            color="primary"
                            size="56"
                            ></v-avatar>
                        </v-list-item>
                    </base-card>
                </v-col>
                    <v-col cols="12" md="4">
                    <base-card>
                        <v-list-item three-line>
                            <v-list-item-content>
                                <!-- <v-list-item-subtitle class="body-2">Total</v-list-item-subtitle> -->
                                <div class="body-2 mb-0 text--disabled">
                                    PROJETS
                                </div>
                                <v-list-item-title
                                    class="headline mb-1 font-weight-bold"
                                    >5672</v-list-item-title
                                >
                            </v-list-item-content>

                            <v-avatar
                            color="primary"
                            size="56"
                            ></v-avatar>
                        </v-list-item>
                    </base-card>
                </v-col>
              
           

                <v-col cols="12">
                    <base-card>
            <v-carousel hide-delimiters cycle  height="500">
    <v-carousel-item
      v-for="(item,i) in items2"
      :key="i"
      
     >
      <v-img :src="item.src" contain max-height="500"></v-img>
    </v-carousel-item>
  </v-carousel>
           </base-card>
                </v-col>
                <v-col cols="12">
                    <base-card>
                        <v-card-title class="justify-space-between">
                            <div class="card-title ma-0 ">
                                Application Received Time
                            </div>
                            <v-menu left bottom>
                                <template v-slot:activator="{on}">
                                    <v-btn icon color="primary" v-on="on">
                                        <v-icon class="body-1"
                                            >mdi-calendar</v-icon
                                        >
                                    </v-btn>
                                </template>

                                <v-list>
                                    <v-list-item>
                                        <v-list-item-title
                                            >View</v-list-item-title
                                        >
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-title
                                            >Add Item</v-list-item-title
                                        >
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-title
                                            >Delete</v-list-item-title
                                        >
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-card-title>
                        <v-card-text>
                            <apexchart
                                type="line"
                                height="350"
                                :options="jmLineChart.chartOptions"
                                :series="jmLineChart.series"
                            />
                        </v-card-text>
                    </base-card>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" lg="3" class="mt-3">
            <base-card>
                <v-row>
                    <v-col cols="12" class="text-center">
                        <v-avatar size="90">
                            <v-img src="@/assets/images/faces/15.jpg" />
                        </v-avatar>
                        <div class="card-title ma-0 text-h5">FAKO Ismael</div>
                        <p class="text--disabled mb-1">Administrateur</p>
                        <div class="d-flex align-center justify-center">
                            <v-icon class="text-primary body-1 mr-1"
                                >mdi-map-marker</v-icon
                            >
                            <span class="text-primary text-12"
                                >Kindonou, Benin</span
                            >
                        </div>
                    </v-col>
                    <v-col cols="12" class="mb-0">
                        <v-card-subtitle class="pt-0">
                            <h6 class="caption tracking-widest">
                                Poste occupé
                            </h6>
                        </v-card-subtitle>
                        <v-card-text class="pb-0">
                            <v-sheet
                                color="primary"
                                class="pr-2 d-flex justify-space-between bg-primary rounded pa-4"
                            >
                                <div class="flex-1">
                                    <div
                                        class="card-title white--text mb-xs text-15 mb-1"
                                    >
                                        Chef departement informatique
                                    </div>
                                    <p class="ma-0 caption d-flex white--text">
                                        <span
                                            style="max-width: 1.25rem"
                                            class="rounded-circle bg-blue-400 h-5 w-5 white--text d-flex align-center justify-center mr-1"
                                            ></span
                                        >
                                        
                                    </p>
                                </div>
                                <v-menu left bottom>
                                    <template v-slot:activator="{on}">
                                        <v-btn icon color v-on="on">
                                            <v-icon class="white--text"
                                                >mdi-dots-horizontal</v-icon
                                            >
                                        </v-btn>
                                    </template>

                                   
                                </v-menu>
                            </v-sheet>
                        </v-card-text>
                    </v-col>
                    <v-col cols="12">
                        <v-card-text>
                            <div class="mb-10">
                                <v-card-subtitle class="pa-0">
                                    <h6 class="caption tracking-widest">
                                        Informations Entreprise
                                    </h6>
                                </v-card-subtitle>
                                <v-list-item
                                    class="pl-0"
                                    v-for="item in items"
                                    :key="item.title"
                                >
                                    <v-list-item-avatar>
                                        <v-icon
                                            :class="[item.iconClass]"
                                            v-text="item.icon"
                                        />
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title
                                            class="body-2 font-weight-medium"
                                            v-text="item.title"
                                        />
                                        <v-list-item-subtitle
                                            v-text="item.subtitle"
                                        />
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <v-btn icon>
                                            <v-icon
                                                class="dark--text text--lighten-3"
                                                >mdi-dots-horizontal</v-icon
                                            >
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </div>
                        </v-card-text>
                    </v-col>
                </v-row>
            </base-card>
            <v-row>
                <v-col cols="12">
                    <base-card>
                        <v-card-title class="justify-space-between">
                            <div class="card-title ma-0 ">
                                Candidates by Gender
                            </div>
                        </v-card-title>
                        <v-card-text>
                            <apexchart
                                type="donut"
                                :options="jmDonutChart.chartOptions"
                                :series="jmDonutChart.series"
                            />
                        </v-card-text>
                    </base-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
import avatarGroupCard from '@/components/card/AvatarGroupCard'
import {
    jmPieChartOne,
    jmPieChartTwo,
    jmBarChart,
    jmLineChart,
    jmDonutChart
} from '@/data/jobManagement'

export default {
    name: 'job-management',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Accueil'
    },
    components: {
        avatarGroupCard
    },
    data() {
        return {
            jmPieChartOne,
            jmPieChartTwo,
            jmBarChart,
            jmLineChart,
            jmDonutChart,
            valueDeterminate: 50,
            valueDeterminate2: 20,
            valueDeterminate3: 60,
            valueDeterminate4: 34,
            valueDeterminate5: 35,
            valueDeterminate6: 15,
            desserts: [
                {
                    job: 'Senior Fullstack Engineer',
                    posted: '01/10/2020',
                    applicants: require('@/assets/images/avatars/001-man.svg')
                },
                {
                    job: 'Freelance Java Developer',
                    posted: '01/10/2020',
                    applicants: require('@/assets/images/avatars/001-man.svg')
                },
                {
                    job: 'Python Developer',
                    posted: '01/10/2020',
                    applicants: require('@/assets/images/avatars/001-man.svg')
                },
                {
                    job: 'Product Designer',
                    posted: '01/10/2020',
                    applicants: require('@/assets/images/avatars/001-man.svg')
                }
            ],
            items: [
                {
                    icon: 'mdi-office-building-marker-outline',
                    iconClass: 'dark--text text--lighten-4 grey lighten-4',
                    title: 'Raison sociale',
                    subtitle: 'Institution LE BAROMETRE'
                },
                {
                    icon: 'mdi-google-maps',
                    iconClass: 'dark--text text--lighten-4 grey lighten-4',
                    title: 'Adresse',
                    subtitle: 'Kindonou, Cotonou , BENIN'
                },
                {
                    icon: 'mdi-phone',
                    iconClass: 'dark--text text--lighten-4 grey lighten-4',
                    title: 'Contact',
                    subtitle: '00229 69494072'
                },
                {
                    icon: 'mdi-email',
                    iconClass: 'dark--text text--lighten-4 grey lighten-4',
                    title: 'E-mail service',
                    subtitle: 'Barometre@piebarometre.com'
                }
                ,
                {
                    icon: 'mdi-email',
                    iconClass: 'dark--text text--lighten-4 grey lighten-4',
                    title: 'E-mail info',
                    subtitle: 'info@piebarometre.com'
                }
            ],
            items2: [
          {
            src: 'http://masterapi.piebarometre.digital/webinaire/barometre.jpg',
          },
          {
            src: 'http://masterapi.piebarometre.digital/webinaire/photo1.jpg',
          },
          {
            src: 'http://masterapi.piebarometre.digital/webinaire/photo2.jpg',
          },
          {
            src: 'http://masterapi.piebarometre.digital/webinaire/photo3.jpg',
          },
        ],
        }
    }
}
</script>
